.notification-notice-banner-content{
    margin-top: 20px;
    max-height: 120px;
    overflow-y: auto;
}
.notification-notice-banner-block-container .slick-dots li{
    margin: 0px 5px 6px 5px;
}
.notification-notice-banner-block-container .div-block-14{
    padding-bottom: 3px;
}
.notification-notice-banner-block-container .slick-dots li.slick-active button:before{
    color: var(--accent);
}
.notification-notice-banner-block-container .notification-notice-banner-top-block{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
}
.notification-notice-banner-top-block img{
    width: 17px;
}