/*.af-view .nav{
    z-index: 99;
    position: fixed!important;
    width: 100%;
}
.af-view .right-content{
    top: 78px!important;
    z-index: 99!important;
}
.af-view .panels{
    padding-top: 80px;
}*/
:root{
  --sl-z-index-toast:99999999!important;
  --sl-z-index-drawer:99999!important;
}

.add_pathway_div {
  margin-top: 100px;
}

.full.w-bar .right-content, .right-content.resize-max-point-reached{
  padding: unset !important;
}

@media screen and (min-width: 480px) {
  .af-view .sk-footer {
    position: fixed;
    bottom: 0;
    z-index: 99;
    background-color: white;
  }

  [dir="ltr"] .af-view .div-block-158 {
    margin-left: 3%;
  }

  [dir="rtl"] .af-view .div-block-158 {
    margin-right: 3%;
  }
}

@media screen and (max-width: 479px) {
  [dir="ltr"] .full.w-bar .right-content {
    left: 0px;
  }

  [dir="rtl"] .full.w-bar .right-content {
    right: 0px;
  }

  .mentor-closer-block {
    top: auto;
    bottom: 0%;
  }

  [dir="ltr"] .mentor-closer-block {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }

  [dir="rtl"] .mentor-closer-block {
    right: 50%;
    left: auto;
    transform: translateX(50%);
  }
}

@media screen and (max-width: 767px) {
  .full.w-bar .image-106.right {
    display: block;
  }
  /*.af-view .right-content {
    z-index: 9 !important;
  }*/
}

.af-view .nav {
  z-index: 9999 !important;
}

.af-view .div-block-164.profile.open {
  display: block !important;
}

.af-view .div-block-164.profile {
  height: max-content !important;
}

.js-calendar-graph-svg {
  width: 100%;
}

.w-embed > .gcont {
  width: 100% !important;
}

[dir="ltr"] .gcont .width-full .float-right .d-inline-block:first-child {
  margin-left: 10px;
}

[dir="rtl"] .gcont .width-full .float-right .d-inline-block:first-child {
  margin-right: 10px;
}

.gcont .width-full {
  display: flex;
  align-items: center;
  justify-content: center;
}

[dir="ltr"] .gcont .width-full .float-right .d-inline-block:last-child {
  margin-right: 10px;
}

[dir="rtl"] .gcont .width-full .float-right .d-inline-block:last-child {
  margin-left: 10px;
}

.gcont > .div-block-33.sp {
  margin-bottom: 5px;
}

[dir="ltr"] .mobile_nav .div-block-163 .div-block-164 {
  left: -100px;
}

[dir="rtl"] .mobile_nav .div-block-163 .div-block-164 {
  right: -100px;
}

.mobile_nav .div-block-164.profile {
  top: 118%;
}

.course-title-top-menu-block {
  position: sticky;
  border-bottom: 1px solid #d2d0d0;
  top: 79px;
  z-index: 2;
  background-color: #fff;
}

.secondary_menu {
  top: 123px;
  z-index: 2;
}

.div-block-631 {
  padding-top: 0px;
  top: 196px;
}

.div-block-632 {
  top: 40px;
}

.course_inside_menu {
  margin-top: 0;
  padding-top: 0px;
  display: flex;
  position: fixed;
  overflow: visible;
  top: 174px;
  max-height: calc(100vh - 174px - 56px);
}

.inside_page {
  top: 17px;
}

.secondary_options {
  min-width: unset !important;
}

.secondary_link {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

[dir="ltr"] .secondary_link {
  margin-right: 0 !important;
}

[dir="rtl"] .secondary_link {
  margin-left: 0 !important;
}

[dir="ltr"] .link-options-3 {
  margin-right: 0 !important;
}

[dir="rtl"] .link-options-3 {
  margin-left: 0 !important;
}

.div-block-536 .button-5 {
  width: 100%;
}

@media screen and (min-width: 1280px) {
  .button-5 {
    background-color: var(--accent);
  }
}

.panels-3 h1,
.panels-3 h2,
.panels-3 h3,
.panels-3 h4,
.panels-3 h5,
.panels-3 h6 {
  font-size: 16px;
  line-height: 20px;
}

.inside_page.in_tab {
  padding-top: 0 !important;
}

.secondary_link {
  max-width: none;
}

.secondary_menu {
  overflow: auto;
}

.main-content > .div-block-326 {
  overflow: auto;
  height: 100%;
}

@media screen and (max-width: 991px) {
  .slider-9 {
    display: block !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .div-block-231 {
    display: flex;
  }

  .dash_nav_item,
  .dash_nav_item.active {
    margin-bottom: -37px;
    padding-bottom: 25px;
  }

  [dir="ltr"] .dash_nav_item,
  [dir="ltr"] .dash_nav_item.active {
    margin-right: 32px;
  }

  [dir="rtl"] .dash_nav_item,
  [dir="rtl"] .dash_nav_item.active {
    margin-left: 32px;
  }

  .nav-right {
    display: flex;
  }

  .nav-right .form-block {
    display: none;
  }

  .form-block.mobile {
    display: none;
  }

  .mobile_nav_trigger {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .main-content > .div-block-326 {
    width: 100%;
  }

  [dir="ltr"] .main-content {
    padding-right: 60px;
  }

  [dir="rtl"] .main-content {
    padding-left: 60px;
  }
}

@media screen and (max-width: 767px) {
  .panels {
    min-height: calc(100% - 60px);
  }
}

.browse_cont .browse_left {
  min-width: 180px;
}

.browse_cont .br_panel {
  width: 100%;
}

@media screen and (min-width: 768px) {
  [dir="ltr"] .browse_cont .br_panel {
    margin-left: 20px;
  }

  [dir="rtl"] .browse_cont .br_panel {
    margin-right: 20px;
  }
}

@media screen and (max-width: 479px) {
  .item-box.path_box.custom_box {
    min-height: 150px !important;
  }

  .item-box.path_box {
    min-height: auto;
  }

  .div-block-444.credentials_box {
    min-height: 150px !important;
  }
}
/* The mini-switch - the box around the mini-switch-slider */
.mini-switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 15px;
}
/* Hide default HTML checkbox */
.mini-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* The mini-switch-slider */
.mini-switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.mini-switch-slider:before {
  position: absolute;
  content: '';
  height: 9px;
  width: 9px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}

[dir="ltr"] .mini-switch-slider:before {
  left: 4px;
}

[dir="rtl"] .mini-switch-slider:before {
  right: 4px;
}

input:checked + .mini-switch-slider {
  background-color: var(--accent);
}

input:focus + .mini-switch-slider {
  box-shadow: 0 0 1px var(--accent);
}

[dir="ltr"] input:checked + .mini-switch-slider:before {
  transform: translateX(14px);
}

[dir="rtl"] input:checked + .mini-switch-slider:before {
  transform: translateX(-14px);
}
/* Rounded mini-switch-sliders */
.mini-switch-slider.round {
  border-radius: 20px;
}

.mini-switch-slider.round:before {
  border-radius: 50%;
}

.ibl-mobile-menu-sidebar
  .ibl-mobile-menu-sidebar-user-profile-dropdown
  .ibl-menu-dropdown-container {
  top: 65px;
}

[dir="ltr"] .ibl-mobile-menu-sidebar
  .ibl-mobile-menu-sidebar-user-profile-dropdown
  .ibl-menu-dropdown-container {
  right: -22px;
}

[dir="rtl"] .ibl-mobile-menu-sidebar
  .ibl-mobile-menu-sidebar-user-profile-dropdown
  .ibl-menu-dropdown-container {
  left: -22px;
}

.ibl-mobile-menu-sidebar
  .ibl-mobile-menu-sidebar-user-profile-dropdown
  .ibl-user-profile-img {
  height: 60px !important;
}

.ibl-mobile-menu-sidebar .dash_nav_item.no-margin {
  width: max-content;
}

.ibl-mobile-menu-sidebar.open {
  display: flex !important;
}

.ibl-mobile-menu-sidebar .dash_nav_item.active {
  margin-bottom: 10px;
}

.flex-block-21-custom {
  padding: 0 !important;
}

/*
#641 CSS UPGRADE*/
.main-content .div-block-630 .link-options-3 {
  padding: 16px 0px 8px!important;
}

.main-content .div-block-630 {
  column-gap: 29px;
  height: 100%;
}

.main-content .course-title-top-menu-block {
  margin-top: 76px;
  margin-bottom: 0px;
  border-bottom: 1px solid;
  border-bottom: 1px solid #d2d0d0!important;
  z-index: 9998;
  height: 50px !important;
}

@media screen and (max-width: 991px) {
  .main-content .course-title-top-menu-block {
    z-index: 998;
  }
}

.div-block-630 .link-options-3 {
  padding-left: 0px;
  padding-right: 0px;
}

.main-content .secondary_menu {
  padding: 15px;
  border-bottom: unset;
  margin-top: 20px;
  position: absolute;
}

[dir="ltr"] .main-content .secondary_menu {
  padding-left: calc(3% - 14px);
}

[dir="rtl"] .main-content .secondary_menu {
  padding-right: calc(3% - 14px);
}

[dir="ltr"] .main-content .course-title-top-menu-block {
  padding-left: calc(3% + 12px);
}

[dir="rtl"] .main-content .course-title-top-menu-block {
  padding-right: calc(3% + 12px);
}

.main-content .course_inside_menu {
  top: 200px;
}

[dir="ltr"] .main-content .course_inside_menu {
  margin-left: calc(3% + 1px);
  border-left: 1px solid #c7c7c7;
}

[dir="rtl"] .main-content .course_inside_menu {
  margin-right: calc(3% + 1px);
  border-right: 1px solid #c7c7c7;
}

.main-content .course_inside_menu.unexpanded {
  border: unset !important;
}

.main-content .left_m {
  overflow: auto;
  /*padding-right: 10px;*/
}

.main-content .left_m-copy.right {
  position: absolute;
  top: 6px;
  box-shadow: unset;
  background-color: transparent;
}

[dir="ltr"] .main-content .left_m-copy.right {
  left: -37px;
  border-left: unset;
}

[dir="rtl"] .main-content .left_m-copy.right {
  right: -37px;
  border-right: unset;
}

@media screen and (max-width: 480px) {
  .main-content .left_m-copy.right {
    top: -33px;
  }

  [dir="ltr"] .main-content .left_m-copy.right {
    left: -5px;
  }

  [dir="rtl"] .main-content .left_m-copy.right {
    right: -5px;
  }
}

.main-content .inside_page {
  top: 67px;
}

[dir="ltr"] .main-content .inside_page {
  margin-right: 13px;
}

[dir="rtl"] .main-content .inside_page {
  margin-left: 13px;
}

@media screen and (max-width: 480px) {
  .main-content .inside_page{
    top: 0px!important;
  }
}

@media screen and (max-width: 480px) {
  [dir="ltr"] body:has(.course_inside_menu.unexpanded) .inside_page {
    margin-left: 5px !important;
  }

  [dir="rtl"] body:has(.course_inside_menu.unexpanded) .inside_page {
    margin-right: 5px !important;
  }
}

.course_inside_menu {
  max-height: calc(100% - 265px);
}

.course-outline-collapser {
  position: absolute;
  top: -9px;
  cursor: pointer;
  opacity: 1;
}

[dir="ltr"] .course-outline-collapser {
  right: -10px;
}

[dir="rtl"] .course-outline-collapser {
  left: -10px;
}

.course-outline-collapser:hover {
  opacity: 0.7;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.main-content .course_inside_menu .left_m::-webkit-scrollbar,
.main-content > .div-block-326::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.main-content .course_inside_menu .left_m,
.main-content > .div-block-326 {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.main-content .faq-column-wrapper {
  margin-top: unset;
}

.panel-profile.panel {
  width: 100%;
  padding: 0px 5px;
}

@media screen and (max-width: 480px) {
  [dir="ltr"] .main-content .course-title-top-menu-block {
    padding-left: 15px !important;
  }

  [dir="rtl"] .main-content .course-title-top-menu-block {
    padding-right: 15px !important;
  }

  .main-content .secondary_menu {
    padding: 15px 9px;
  }

  [dir="ltr"] .main-content .left_m-copy.right {
    left: -7px;
  }

  [dir="rtl"] .main-content .left_m-copy.right {
    right: -7px;
  }

  [dir="ltr"] .main-content .course_inside_menu {
    margin-left: 19px;
  }

  [dir="rtl"] .main-content .course_inside_menu {
    margin-right: 19px;
  }

  .panel-profile.panel {
    padding: 0px 0px;
  }
}

.course_inside_menu .collapse_h {
  margin-top: unset;
}

.course-outline-uncollapser {
  opacity: 0.7;
}

.course-outline-uncollapser:hover {
  opacity: 1;
}

.faq-question-bar-3 {
  padding-left: 10px;
  padding-right: 10px;
}

.f_box {
  padding-bottom: 5px;
  padding-top: 0px;
}

[dir="ltr"] body:has(.panel-browse.panel) .full.w-bar .panels_cont {
  margin-right: 20px !important;
}

[dir="rtl"] body:has(.panel-browse.panel) .full.w-bar .panels_cont {
  margin-left: 20px !important;
}

.prof_box.pp .text-block {
  overflow-wrap: anywhere;
}

@media screen and (max-width: 480px) {
  .main-content .secondary_menu {
    padding: 25px 32px 8px 32px;
    z-index: 999;
    scrollbar-width: none;
  }

  .secondary_options{
    overflow: auto;
    display: inline-block;
    width: max-content;
  }

  [dir="ltr"] .secondary_options{
    margin-right: 30px;
  }

  [dir="rtl"] .secondary_options{
    margin-left: 30px;
  }

  .secondary_link{
    display: inline;

  }

  [dir="ltr"] .secondary_link{
    padding-right: 0px !important;

  }

  [dir="rtl"] .secondary_link{
    padding-left: 0px !important;

  }

  .f_a.dr{

    display: inline;
    margin: 0px 10px;

  }
}

.course_inside_menu{
  z-index: 999!important;
}

.course-outline-mobile-trigger-btn{
  position: relative;
  top: 66px;
}

[dir="ltr"] .course-outline-mobile-trigger-btn{
  left: 20px;
}

[dir="rtl"] .course-outline-mobile-trigger-btn{
  right: 20px;
}

.course_inside_menu.course_inside_menu_mobile{
  position: static;
  max-height: none;
  border: 1px solid #c7c7c7!important;
}

[dir="ltr"] .course_inside_menu.course_inside_menu_mobile{
  margin-left: unset;
}

[dir="rtl"] .course_inside_menu.course_inside_menu_mobile{
  margin-right: unset;
}

.course_inside_menu.course_inside_menu_mobile .left_m{
  width: 100% !important;
}

.course_inside_menu.course_inside_menu_mobile  .faq-question-wrap-3{
  margin-bottom: unset!important;
}

.div-block-84{
  margin-top: unset!important;
}

.image-153 {
  max-height: 100px;
  max-width: 100px;
  height: unset;
}

.top-menu-block{
  display: flex!important;
  height: 100% !important;
}

.main-content .secondary_options{
  overflow: auto;
  scrollbar-width: none;
}

.main-content .secondary_menu{
  margin-top: unset;
  top: 128px;
  position: sticky;
}

[dir="ltr"] .main-content .secondary_menu{
  padding: 26px 15px 27px calc(3% + 12px)!important;
}

[dir="rtl"] .main-content .secondary_menu{
  padding: 26px calc(3% + 12px) 27px 15px!important;
}

[dir="ltr"] .html-embed-19{
  margin-left: unset!important;
}

[dir="rtl"] .html-embed-19{
  margin-right: unset!important;
}

.main-content > .div-block-326{
  position: relative;
  overflow: auto;
}

.main-content .inside_page{
  top: unset;
}

@media screen and (max-width: 480px) {
  .mentor-modal-available{
    width: calc(100% - 55px) !important;
  }

  .main-content .secondary_menu{
    padding: 13px 15px 12px 15px !important;
  }

  .course-main-content-header-block{
    top: 178px!important;
    padding: 5px 0;
  }

  .main-content > .div-block-326{
    overflow: auto;
  }

  [dir="ltr"] .secondary_options{
    padding-left: 0px !important;
  }

  [dir="rtl"] .secondary_options{
    padding-right: 0px !important;
  }
}

[dir="ltr"] .toolbar-div.grade{
  border-right: unset!important;
}

[dir="rtl"] .toolbar-div.grade{
  border-left: unset!important;
}

[dir="ltr"] .secondary_menu .secondary_options .secondary_link:first-child{
  padding-left: 0px !important;
}

[dir="rtl"] .secondary_menu .secondary_options .secondary_link:first-child{
  padding-right: 0px !important;
}

.div-block-326{
  min-width: auto!important;
}

.panel-profile.panel > span .panels{
  padding-top: unset;
}

[dir="ltr"] .full.w-bar .assigned-contents-block > .panels_cont{
  margin-left: unset!important;
}

[dir="rtl"] .full.w-bar .assigned-contents-block > .panels_cont{
  margin-right: unset!important;
}

[dir="ltr"] .full.w-bar .assigned-contents-block .browse_cont .br_panel{
  margin-left: unset!important;
}

[dir="rtl"] .full.w-bar .assigned-contents-block .browse_cont .br_panel{
  margin-right: unset!important;
}

.custom-tab-header-menu-block{
  display: flex;
  gap: 20px;
  border-bottom: 1px solid #dde1e6;
}

.custom-tab-header-menu-block .custom-tab-menu{
  padding: 20px 20px;
  top: 1px;
  cursor: pointer;
}

.custom-tab-header-menu-block .custom-tab-menu.active{
  z-index: 999999;
  position: relative;
  border-left: 1px solid #dde1e6;
  border-right: 1px solid #dde1e6;
  border-top: 1px solid #dde1e6;
  border-bottom: 2px solid white;
}

[dir="ltr"] .custom-tab-header-menu-block .custom-tab-menu.active{
  border-radius: 7px 7px 0px 0;
}

[dir="rtl"] .custom-tab-header-menu-block .custom-tab-menu.active{
  border-radius: 7px 7px 0 0px;
}

[dir="ltr"] .with-custom-tab .div-block-430.sl{
  padding-left: unset!important;
}

[dir="rtl"] .with-custom-tab .div-block-430.sl{
  padding-right: unset!important;
}

.pagination.m-t-20{
  margin-top: 20px;
}

.slick-slide .text-block-9{
  min-height: 40px;
}

@media screen and (min-width: 1280px) {
  body:has(.af-view.use-full-width) .panels_cont, body:has(.af-view.use-full-width) .p_menu, body:has(.af-view.use-full-width) .sk-footer > .div-block-158{
    max-width: unset!important;
    margin-left: 3% !important;
    margin-right: 3% !important;
  }
}

.pathway_grid, .course_grid{
  grid-template-columns: repeat(auto-fill, minmax(1fr, 1fr));
}

.pathway_grid .text-block-225, .course_grid .text-block-225{
  overflow-wrap: break-word!important;
  word-break: break-all!important;
}

.panel.active.more-pad-bottom{
  padding-bottom: 30px;
}