.div-block-233 {
     cursor: inherit;

}

.button-choose-file{
     cursor: pointer;
}

.g_s_btn.disabled {
     opacity: .57;
     cursor: auto;
}

.g_s_btn-before {
     color: #fff;
     cursor: pointer;
     border-radius: 4px;
     justify-content: center;
     align-items: center;
     width: 150px;
     height: 49px;
     display: flex;
}

[dir="ltr"] .g_s_btn-before {
     background-image: linear-gradient(to right, #b6b6b6, #bbc0c5);
     margin-left: 0;
     margin-right: auto;
}

[dir="rtl"] .g_s_btn-before {
     background-image: linear-gradient(to left, #b6b6b6, #bbc0c5);
     margin-right: 0;
     margin-left: auto;
}

[dir="ltr"] .text-block-157 {
     padding-right: 24px;
}

[dir="rtl"] .text-block-157 {
     padding-left: 24px;
}
.start-optional-upload-box{
     cursor: pointer;
}
.start-optional-upload-box .input-mentor-image-upload {
     border: 0;
     clip: rect(0 0 0 0);
     height: 1px;
     margin: -1px;
     overflow: hidden;
     padding: 0;
     position: absolute;
     width: 1px;
}